import { graphql } from "gatsby"
import React from "react"
import Contact from "../components/Contact"
import WrapperRight from "../components/WrapperRight"

const PostDetail = ({ data }) => {
  
  return (
    <>
      <section className="container jobs-listing">
        <div className="row">
          <div className="col-lg-9">
            <p>JOIN INFO AND FORM THEN</p>
            <p>ALREADY GOT THE POST INFO</p>
            <Contact />
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default PostDetail

export const query = graphql`
  query PostDetailsQuery($slug: String!) {
    allStrapiPosts(filter: { id: { eq: $slug } }) {
      nodes {
        id
        description
        created_at(formatString: "MM/DD/YYYY")
        nickname
        title
        post_category {
          id
          postCategory
        }
      }
    }
  }
`
